import React, { Fragment, useEffect } from 'react'
import { styled } from "@mui/material/styles";
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import EmptyTableMessage from '../../HOC/FunctionComponents/EmptyTableMessage';
import { Stack, Typography } from '@mui/material';

export const batchFields = [
    "id",
    { barcodes: ["id", "code"] }
];

export const shipmentFields = [
    {
        data: [
            "code",
            "recipientName",
            "recipientAddress",
            "recipientMobile",
            "recipientPhone",
            "totalAmount",
            {
                recipientZone: ["name"],
            },
            {
                recipientSubzone: ["name"],
            },
        ],
    },
];

const PREFIX = "SHIPMENT_STICKY_PRINT";

export const classes = {
    printSticky: `${PREFIX}-printSticky`,
    stickyCode: `${PREFIX}-stickyCode`,
    stickyRecipientName: `${PREFIX}-stickyRecipientName`,
    stickyDestination: `${PREFIX}-stickyDestination`,
    stickyRecipientAddress: `${PREFIX}-stickyRecipientAddress`,
    centerContent: `${PREFIX}-centerContent`,
    barcode: `${PREFIX}-barcode`,
    logoImg: `${PREFIX}-logoImg`,
};
export const Root = styled("div")(({ theme }) => ({
    fontSize: "10px",
    [`& p`]: {
        fontSize: "10px",
    },
    [`& .${classes.printSticky}`]: {
         
        pageBreakInside: "avoid",
        width: "calc(5cm -  2mm)",
        height: "calc(4cm -  2mm)",
        overflow: "hidden",
        fontSize: 11,
        color: "black",
        lineHeight: "1.4",
        border: "1px solid #000",
        padding: theme.spacing(0.5)
    },

    [`& .${classes.stickyCode}`]: {
        overflow: "hidden",
        fontSize: "30px !important",
    },

    [`& .${classes.barcode}`]: {
        fontFamily: "'Libre Barcode 39 Text'",
        fontSize: 48,
    },

    [`& .${classes.logoImg}`]: {
        maxHeight: "30px",
        maxWidth: "fit-content"
    },
}));

function createDynamicStyles() {
    const style = document.createElement('style');
    style.type = 'text/css';

    const styles = `
    @page {
        size: 5cm 4cm; 
        margin: 1mm !important;
        overflow: hidden;
    }
    * {
            color: black;
    }
    body {
            background: #fff !important
    }
    `;

    if (style.styleSheet) {
        style.styleSheet.cssText = styles;
    } else {
        style.appendChild(document.createTextNode(styles));
    }

    document.head.appendChild(style);
}

const Template5X4 = (props) => {
    const {
        loading,
        validData,
        parsedData,
        isBatch,
        LogoImg,
        currency
    } = props

    const { t } = useTranslation()

    useEffect(() => {
        createDynamicStyles()

    }, [])

    return (
        <Fragment>
            {loading || (!loading && !validData) ? (
                <>
                    <EmptyTableMessage
                        loading={loading}
                        message={t("noShipmentWithCode")}
                    />
                </>
            ) : (
                parsedData.map((shipment, index) => (
                    <Root key={index}>
                        <Stack spacing={0.2} className={clsx({ [classes.printSticky]: !loading && validData, })}>
                            <Stack alignItems={"center"}>
                                <LogoImg forceLight={true} className={classes.logoImg} />
                            </Stack>
                            <Stack
                                alignItems={"center"}
                                className={clsx(
                                    classes.barcode,
                                    classes.stickyCode
                                )}
                            >
                                *{shipment.code}*
                            </Stack>
                            <Typography className={classes.stickyRecipientName}>
                                - {shipment?.recipientName}
                            </Typography>
                            <Typography className={classes.stickyDestination}>
                                - {!isBatch && " " +
                                    shipment?.recipientZone?.name +
                                    " - " +
                                    shipment?.recipientSubzone?.name}
                            </Typography>
                            <Typography className={classes.stickyDestination}>
                                - {isBatch ? ' ' : (shipment?.recipientMobile &&
                                    shipment?.recipientPhone
                                    ? `${shipment?.recipientMobile} - ${shipment?.recipientPhone}`
                                    : shipment?.recipientMobile ??
                                    shipment?.recipientPhone)}
                            </Typography>
                            <Typography className={classes.stickyDestination}>
                                - {t("cod")}: {shipment?.totalAmount ?? 0} {!isBatch && currency}
                            </Typography>

                        </Stack>
                    </Root>
                ))
            )}
        </Fragment>
    )
}

export default Template5X4